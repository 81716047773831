import cls from "./customHeader.module.scss"
import React, {FC} from "react";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import {IFirstHeader, ISecondHeader} from "../MainLayout";
import {useSelector} from "react-redux";
import {IState} from "../../../../types/state";

interface IProps extends IFirstHeader, ISecondHeader {
    isMobile: boolean;
}

const CustomHeader: FC<IProps> = (
    {
        logoPath,
        dbName,
        domain,
        menus,
        hasBlog,
        selectedRate,
        isTwoKings,
        categories,
        selectedLocale,
        translationsData,
        backOrderValue,
        isSocialLinksActive,
        allowCheckoutValue,
        selectedLocaleImg,
        locales,
        cacheControlVersion,
        isMobile,
        defaultLangCode
    }
) => {
    const openSearch = useSelector((state: IState) => state.mobileMenu.searchOpen) || false;
    const secondSectionProps = {
        isMobile, selectedRate, isTwoKings, categories,
        dbName, selectedLocale, translationsData, backOrderValue,
        isSocialLinksActive, allowCheckoutValue, defaultLangCode,
        menus, hasBlog, logoPath
    }
    return <div className={cls.header}>
        <FirstSection
            dbName={dbName}
            domain={domain}
            logoPath={logoPath}
            menus={menus}
            hasBlog={hasBlog}
            selectedLocale={selectedLocale}
            selectedLocaleImg={selectedLocaleImg}
            translationsData={translationsData}
            locales={locales}
            backOrderValue={backOrderValue}
            selectedRate={selectedRate}
            cacheControlVersion={cacheControlVersion}
        />
        {!isMobile ? <SecondSection {...secondSectionProps}/>
            : openSearch ? <SecondSection {...secondSectionProps}/> : null}
    </div>
};

export default CustomHeader;